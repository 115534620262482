<template>
  <div class="div-block-7 article-content-subpage">
    <div class="div-block-14">
      <h1>Kontakt</h1>
    </div>
    <div class="block-article--intro">
      <div class="block-article-content">
        <div class="w-richtext">
          <p>
            Über unsere Internetseite probieren wir soviele Fragen wie möglich
            im Vorfeld zu beantworten. Falls dennoch eine Frage offen sein
            sollte, stehen wir gerne über unser Kontaktformular zur Verfügung.
          </p>
          <p>
            Nach dem Erhalt deiner Nachricht melden wir uns so schnell wie
            möglich bei dir zurück!
          </p>
        </div>
      </div>
    </div>
    <div>
      <form class="ui form" @submit.prevent="processForm" v-if="!success">
        <div class="field required">
          <label>Dein Name</label>
          <input
            type="text"
            name="name"
            placeholder="Name eintragen …"
            v-model.trim="email.name"
            required
          />
        </div>
        <div class="field required">
          <label>Deine E-Mail-Adresse</label>
          <input
            type="email"
            name="email"
            placeholder="mail@adresse.de"
            v-model.trim="email.sender"
            required
          />
        </div>
        <div class="field required">
          <label>Nachricht</label>
          <textarea
            name="message"
            v-model.trim="email.message"
            style="white-space: pre"
            required
          ></textarea>
        </div>
        <div class="field required">
          <div class="ui checkbox" required>
            <input type="checkbox" tabindex="0" class="hidden" required />
            <label
              >Ich stimme zu, dass meine Angaben vom Skiklub Oestertal zur
              Kontaktaufnahme genutzt werden.</label
            >
          </div>
        </div>
        <button
          class="ui primary big button"
          type="submit"
          :class="{ loading }"
        >
          Formular absenden
        </button>
      </form>
      <div v-else-if="success">
        <div class="ui message success">
          <div class="header" style="padding-bottom: 10px">
            Nachricht gesendet :)
          </div>
          Vielen Dank für die Nachricht! Wir melden uns so schnell wie möglich
          zurück.
        </div>
      </div>
      <div v-if="error">
        <div
          class="ui message error"
          style="margin-top: 20px; margin-bottom: 20px"
        >
          <div class="header" style="padding-bottom: 10px">
            Nachricht leider nicht gesendet… :(
          </div>
          Beim Versenden der Nachricht ist leider ein Fehler aufgetreten. Bitte
          versuche es zu einem späteren Zeitpunkt noch einmal!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";

export default {
  name: "contact",
  metaInfo: {
    title: "Kontakt",
  },
  data() {
    return {
      loading: false,
      success: false,
      error: null,
      email: {
        name: null,
        sender: null,
        message: null,
      },
    };
  },
  mounted() {
    $(".ui.checkbox").checkbox();
  },
  methods: {
    processForm() {
      this.success = false;
      this.loading = true;
      this.error = null;

      var functions = firebase.app().functions("europe-west1");
      var sendContactEmail = functions.httpsCallable("sendContactEmail");
      sendContactEmail({
        sender: this.email.sender,
        name: this.email.name,
        message: this.email.message,
      })
        .then(() => (this.success = true))
        .catch(error => {
          // Getting the Error details.
          var code = error.code;
          var message = error.message;
          var details = error.details;
          this.error = {
            code,
            message,
            details,
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
